const NAMESPACE = '/';
const NAMESPACE_PDV = '/pdv';
const NAMESPACE_REPORTS = '/reports';
const NAMESPACE_LOGIN = '/login';
const NAMESPACE_RECEIPT = '/receipt';
const NAMESPACE_TERMINAL = '/terminal';

const TERMINAL_ROUTES = {
  ROOT: NAMESPACE_TERMINAL,
};

const LOGIN_ROUTES = {
  ROOT: NAMESPACE_LOGIN,
  CHANGE_PASSWORD: `${NAMESPACE_LOGIN}/change-password`,
};

const EXTERNAL_PINPAD_ROUTE = 'https://127.0.0.1/agente/clisitef';

const EXTERNAL_ROUTES = {
  PINPAD: EXTERNAL_PINPAD_ROUTE,
};

export const NAMESPACES = {
  ROOT: NAMESPACE,
  PDV: NAMESPACE_PDV,
  REPORTS: NAMESPACE_REPORTS,
  LOGIN: NAMESPACE_LOGIN,
  RECEIPT: NAMESPACE_RECEIPT,
  TERMINAL: NAMESPACE_TERMINAL,
};

const ROUTES = {
  HOME: NAMESPACE,
  LOGIN: LOGIN_ROUTES,
  EXTERNAL: EXTERNAL_ROUTES,
  TERMINAL: TERMINAL_ROUTES,
};

export default ROUTES;
