import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, CircularProgress } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';

import terminalStore from '../store';

const QrCodePixComponent = () => {
  const [qrcode, setQrcode] = React.useState('');

  const dispatch = useDispatch();

  const terminalStoreSelectorState = useSelector(
    terminalStore.selector.selectState
  );

  const handleBack = () => () => {
    dispatch(terminalStore.action.updateStepPrev());
  };

  const methodPayment = terminalStoreSelectorState.methodPayment;
  const methodPaymentIsPix = methodPayment === 'pix';

  React.useEffect(() => {
    const currentStep = terminalStoreSelectorState.step;
    const lastStep = terminalStoreSelectorState.lastStep;

    const currentStepHigherThanLastStep = currentStep > lastStep;

    if (!methodPaymentIsPix && currentStepHigherThanLastStep) {
      dispatch(terminalStore.action.updateStepNext());
    }

    if (!methodPaymentIsPix && !currentStepHigherThanLastStep) {
      dispatch(terminalStore.action.updateStepPrev());
    }
  }, []);

  React.useEffect(() => {
    const lastLogSocket =
      terminalStoreSelectorState.logSocket[
        terminalStoreSelectorState.logSocket.length - 1
      ];

    const lastStates = lastLogSocket?.states[lastLogSocket?.states.length - 1];

    if (lastStates?.qrcode && !qrcode) {
      setQrcode(lastStates?.qrcode);
    }

    if (lastStates?.state === 'completed') {
      dispatch(terminalStore.action.updateResultStatus('success'));
      dispatch(terminalStore.action.updateStepNext());
    }
  }, [terminalStoreSelectorState.logSocket]);

  const passosPIX = [
    "Abra um aplicativo bancário e selecione 'Pagar com PIX'.",
    'Escaneie o QR Code com sua câmera.',
    'Confirme os detalhes do pagamento, como valor e descrição.',
    'E aguarde a confirmação na tela.',
  ];

  return methodPaymentIsPix ? (
    <Box display="flex" flexDirection="column" gap={4}>
      <Button
        onClick={handleBack()}
        variant="contained"
        size="large"
        startIcon={<ChevronLeftIcon />}
        fullWidth
      >
        Voltar
      </Button>
      <Box
        display="flex"
        gap={4}
        alignItems="center"
        width={900}
        sx={{
          margin: '0 auto',
        }}
      >
        <Box
          bgcolor="secondary.light"
          p={2}
          gap={3}
          borderRadius={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box>
            <Typography align="center" variant="trasso_heading_medium">
              Valor a pagar:
              <strong>
                {Number(terminalStoreSelectorState.amount).toLocaleString(
                  'pt-br',
                  {
                    currency: 'BRL',
                    style: 'currency',
                  }
                )}
              </strong>
            </Typography>
          </Box>
          <Box
            width={300}
            height={300}
            bgcolor="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {qrcode && <img src={qrcode} width={300} height={300} />}
            {!qrcode && <CircularProgress />}
          </Box>
        </Box>
        <Box flex={1} display="flex" flexDirection="column" gap={2}>
          <Typography variant="header_sm" color="gray.500">
            Pague com PIX
          </Typography>
          <Box component="ul" m={0}>
            {passosPIX.map((passo, index) => (
              <li key={index}>
                <Typography variant="trasso_body_medium" color="gray.500">
                  {passo}
                </Typography>
              </li>
            ))}
          </Box>

          <Box>
            <Box
              bgcolor="gray.100"
              display="flex"
              alignItems="center"
              gap={2}
              p={1.5}
              borderRadius={1}
            >
              <CircularProgress
                size="20px"
                sx={{
                  color: 'gray.300',
                }}
              />

              <Typography variant="caption" color="gray.300" fontWeight={800}>
                Aguardando pagamento
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default QrCodePixComponent;
