import React from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import websocketUtil from '@giro-pdv-terminal/utils/websocket.util';

import terminalStore from '../store';

// import tablePayment from '../store/tablePayment';
// import fees from '../store/fees';
// import clients from '../store/clients';

const enumTipoTransacao = {
  client: 3,
  ec: 2,
};

const formatToCurrency = (number) =>
  Number(number).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

const TablePaymentComponent = () => {
  const { user } = useAuthHook();

  const dispatch = useDispatch();

  const linkedKey = user?.linkedkey;

  const terminalStoreSelectorState = useSelector(
    terminalStore.selector.selectState
  );

  const selectorRedux = {
    dataClients: {
      retornoGiro: terminalStoreSelectorState?.retornoGiro,
    },
    typeTax: terminalStoreSelectorState?.typeTax,
    feesData: terminalStoreSelectorState?.feesData,
    return: terminalStoreSelectorState?.return,
    amount: terminalStoreSelectorState.amount,
    installmentSelected: {} as any,
  };

  const dispatchRedux = {
    updateInstallmentSelected: flow(
      terminalStore.action.updateInstallmentSelected,
      dispatch
    ),
  };

  const handleBack = () => () => {
    dispatch(terminalStore.action.updateStepPrev());
  };

  const { retornoGiro } = selectorRedux.dataClients || {};

  const idTipoTransacao = enumTipoTransacao[selectorRedux.typeTax];

  const installments = selectorRedux.feesData?.filter(
    (f) => f.cdTipoTransacao === idTipoTransacao
  );

  const rows = installments?.map((r, idx) => {
    let installment_amount,
      amount,
      returnAmount = 0;

    const isDebit = r.cdTipoTaxa === 1;

    if (selectorRedux.return > 0) {
      const returnPlusGiro = selectorRedux.return >= 8 ? 0.5 : 0;
      const returnEc = Number(selectorRedux.return);
      const returnGiro = retornoGiro ? returnEc * 0.25 + returnPlusGiro : 0;

      returnAmount = isDebit
        ? 0
        : selectorRedux.amount * ((selectorRedux.return + returnGiro) / 100);
    }

    if (selectorRedux.typeTax === 'ec') {
      installment_amount = selectorRedux.amount / r.nuParcelas;
      amount = r.vlFator * selectorRedux.amount * r.nuParcelas;
    }

    if (selectorRedux.typeTax === 'client') {
      installment_amount = r.vlFator * (selectorRedux.amount + returnAmount);
      amount = r.vlFator * (selectorRedux.amount + returnAmount) * r.nuParcelas;
    }

    const installment = isDebit ? 'Única' : r.nuParcelas;

    return {
      key: `installment_${idx}`,
      type: isDebit ? 'Débito' : 'Crédito',
      installment,
      installment_amount: formatToCurrency(installment_amount),
      amount: formatToCurrency(amount),
      amount_number: amount,
      installment_amount_number: installment_amount,
    };
  });

  return (
    <Box position="relative" overflow="hidden">
      <Box
        display="flex"
        flexDirection="column"
        gap={4}
        position="sticky"
        top={0}
      >
        <Button
          onClick={handleBack()}
          variant="contained"
          size="large"
          startIcon={<ChevronLeftIcon />}
        >
          Voltar
        </Button>

        <Box display="flex" flexDirection="column" gap={0.5}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="trasso_heading_small" color="grey.800">
              Formas de pagamento
            </Typography>
            <Typography variant="trasso_body_medium" color="grey.800">
              Número de parcelas: {installments?.length - 1}
            </Typography>
          </Box>
          <Box
            border="1px solid transparent"
            borderColor="trasso.gray.40"
            borderRadius="6px"
          >
            <TableContainer>
              <Table sx={{ minWidth: '400px' }} size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 35 }}>
                      <Typography
                        variant="trasso_heading_small"
                        color="grey.800"
                      >
                        Tipo
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: 35 }}>
                      <Typography
                        variant="trasso_heading_small"
                        color="grey.800"
                      >
                        Parcelas
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: 35 }}>
                      <Typography
                        variant="trasso_heading_small"
                        color="grey.800"
                      >
                        Valor parcela
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row, index) => (
                    <TableRow
                      key={row.key}
                      onClick={() => {
                        dispatchRedux.updateInstallmentSelected(row);

                        dispatch(terminalStore.action.updateStepNext());

                        websocketUtil.addEvent(linkedKey, {
                          event: 'pdv-terminal::installment',
                          payload: row,
                        });
                      }}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor:
                          selectorRedux?.installmentSelected?.key === row.key
                            ? 'secondary.light'
                            : undefined,

                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },

                        '&:hover': {
                          backgroundColor: 'secondary.light',
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ width: 35 }}
                      >
                        <Typography
                          variant="trasso_body_medium"
                          color="grey.800"
                        >
                          {row.type}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: 35 }}>
                        <Typography
                          variant="trasso_body_medium"
                          color="grey.800"
                        >
                          {row.installment}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: 35 }}>
                        <Typography
                          variant="trasso_body_medium"
                          color="grey.800"
                        >
                          {row.installment_amount}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TablePaymentComponent;
