import { ACTION_TYPES } from './terminal.constant';

export const updateStep = ({ next, current }: { next; current }) => ({
  type: ACTION_TYPES.UPDATE.STEP,
  payload: {
    next,
    current,
  },
});

export const updateResultStatus = (
  payload: 'loading' | 'success' | 'error'
) => ({
  type: ACTION_TYPES.UPDATE.RESULT_STATUS,
  payload,
});

export const updateState = (payload) => ({
  type: ACTION_TYPES.UPDATE.STATE,
  payload,
});

export const updateLogSocket = (payload) => ({
  type: ACTION_TYPES.UPDATE.LOG_SOCKET,
  payload,
});

export const updatePayment = (payload) => ({
  type: ACTION_TYPES.UPDATE.PAYMENT,
  payload,
});

export const updateInstallmentSelected = (payload) => ({
  type: ACTION_TYPES.UPDATE.INSTALLMENT,
  payload,
});

export const updateAmount = (payload) => ({
  type: ACTION_TYPES.UPDATE.AMOUNT,
  payload,
});

export const updateStepNext = () => ({
  type: ACTION_TYPES.UPDATE.STEP_NEXT,
});

export const updateStepPrev = () => ({
  type: ACTION_TYPES.UPDATE.STEP_PREV,
});

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
