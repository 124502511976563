export const NAME = 'terminal';

const CONTEXT = 'TERMINAL';

const RESET = `${CONTEXT}::RESET`;

const UPDATE_STATE = `${CONTEXT}::UPDATE::STATE`;
const UPDATE_STEP = `${CONTEXT}::UPDATE::STEP`;
const UPDATE_STEP_NEXT = `${CONTEXT}::UPDATE::STEP::NEXT`;
const UPDATE_STEP_PREV = `${CONTEXT}::UPDATE::STEP::PREV`;
const UPDATE_AMOUNT = `${CONTEXT}::UPDATE::AMOUNT`;
const UPDATE_PAYMENT = `${CONTEXT}::UPDATE::PAYMENT`;
const UPDATE_INSTALLMENT = `${CONTEXT}::UPDATE::INSTALLMENT`;
const UPDATE_RESULT_STATUS = `${CONTEXT}::UPDATE::RESULT_STATUS`;
const UPDATE_LOG_SOCKET = `${CONTEXT}::UPDATE::LOG_SOCKET`;

const UPDATE = {
  STATE: UPDATE_STATE,
  AMOUNT: UPDATE_AMOUNT,
  PAYMENT: UPDATE_PAYMENT,
  INSTALLMENT: UPDATE_INSTALLMENT,
  RESULT_STATUS: UPDATE_RESULT_STATUS,
  LOG_SOCKET: UPDATE_LOG_SOCKET,
  STEP: UPDATE_STEP,
  STEP_NEXT: UPDATE_STEP_NEXT,
  STEP_PREV: UPDATE_STEP_PREV,
};

export const ACTION_TYPES = {
  RESET,
  UPDATE,
};
