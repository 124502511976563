import reducer from './terminal.reducer';
import * as action from './terminal.action';
import * as selector from './terminal.selector';
import * as constant from './terminal.constant';
import initialState from './terminal.initialState';
import saga from './terminal.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  saga,
  name: constant.NAME,
};
