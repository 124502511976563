import { combineReducers } from 'redux';

import router from '@giro/shared-store/router';
import toaster from '@giro/shared-store/toaster';
import auth from '@giro/shared-store/auth';

import login from '@giro-pdv-terminal/modules/login/store';
import terminal from '@giro-pdv-terminal/modules/terminal/store';

import dialogReceipt from './dialogReceipt';
import dialogConfirmExit from './dialogConfirmExit';
import menu from './menu';
import clients from './clients';
import transactions from './transactions';
import pinpad from './pinpad';

const appReducer = combineReducers({
  [router.name]: router.reducer,
  [toaster.name]: toaster.reducer,
  [terminal.name]: terminal.reducer,
  [login.name]: login.reducer,
  [auth.name]: auth.reducer,
  [dialogReceipt.name]: dialogReceipt.reducer,
  [dialogConfirmExit.name]: dialogConfirmExit.reducer,
  [menu.name]: menu.reducer,
  [clients.name]: clients.reducer,
  [transactions.name]: transactions.reducer,
  [pinpad.name]: pinpad.reducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
