import React from 'react';
import { flow } from 'lodash';
import { Box, Typography, Divider } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import useDialogHook from '@giro-pdv-terminal/hooks/useDialogRedux.hook';

import messages from '@giro/shared-constants/messagesSchema.constant';

import FieldPassword from '@giro/shared-components/Fields/FieldPassword.component';

import FieldLabelFormikComponent from '@giro-pdv-terminal/components/FieldLabelFormik.component';
import TextFieldLabelKeyboardComponent from '@giro-pdv-terminal/components/TextFieldLabelKeyboard.component';

import BoxLoginLayout from '../layout/BoxLogin.layout';

import login from '../store/login';

import dialogChangeEmail from '../store/dialogs/dialogChangeEmail';

const FormLogin = () => {
  const { handleOpen } = useDialogHook(dialogChangeEmail);

  const dispatch = useDispatch();

  const dispatchRedux = {
    fetchStart: flow(login.action.fetchStart, dispatch),
  };

  const selectorRedux = {
    loading: useSelector(login.selector.selectLoading),
  };

  const schema = Yup.object({
    code: Yup.string().required(messages.REQUIRED),
  });

  const initialValues = {
    code: '',
  };

  const onSubmit = async (values) => {
    dispatchRedux.fetchStart(values);

    return true;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnMount
    >
      {({ errors, submitForm, isSubmitting }) => (
        <Form onSubmit={submitForm}>
          <Box display="flex" flexDirection="column" gap={3}>
            <Box display="flex" flexDirection="column" gap={3}>
              <TextFieldLabelKeyboardComponent
                name="code"
                label="Código"
                placeholder="123456"
              />
            </Box>
            <LoadingButton
              variant="contained"
              fullWidth
              size="large"
              type="submit"
              disabled={selectorRedux.loading || Object.keys(errors).length > 0}
              onClick={submitForm}
              loading={selectorRedux.loading}
            >
              Sincronizar
            </LoadingButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

const LoginScreen = () => {
  return (
    <BoxLoginLayout>
      <FormLogin />
    </BoxLoginLayout>
  );
};

export default LoginScreen;
