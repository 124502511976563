export const NAME = 'clients';

const CONTEXT = 'CLIENTS';

const SERVICE_GET = `${CONTEXT}::SERVICE::GET`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_SUCCESS = `${CONTEXT}::FETCH::SUCCESS`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;

const RESET = {
  STATE: RESET_STATE,
};

const SERVICE = {
  GET: SERVICE_GET,
};

const FETCH = {
  START: FETCH_START,
  SUCCESS: FETCH_SUCCESS,
  ERROR: FETCH_ERROR,
};

export const ACTION_TYPES = {
  RESET,
  SERVICE,
  FETCH,
};
