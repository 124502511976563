import React from 'react';
import { flow } from 'lodash';
import { Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import messages from '@giro/shared-constants/messagesSchema.constant';

import FieldPassword from '@giro/shared-components/Fields/FieldPassword.component';

import FieldLabelFormikComponent from '@giro-pdv-terminal/components/FieldLabelFormik.component';

import BoxLoginLayout from '../layout/BoxLogin.layout';

import login from '../store/login';
import changePassword from '../store/changePassword';

const FormChangePassword = () => {
  const dispatch = useDispatch();

  const selectorRedux = {
    loading: useSelector(changePassword.selector.selectLoading),
  };

  const dispatchRedux = {
    fetchStart: flow(login.action.fetchStart, dispatch),
  };

  const dispatchChangePasswordRedux = {
    servicePost: flow(changePassword.action.servicePost, dispatch),
  };

  const schema = Yup.object({
    password: Yup.string()
      .required(messages.REQUIRED)
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        messages.PASSWORD_SPECS
      ),
    confirmPassword: Yup.string()
      .required(messages.REQUIRED)
      .oneOf(
        [Yup.ref('password'), null],
        messages.PASSWORD_VERIFICATION_EQUALS
      ),
  });

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const onSubmit = async (values) => {
    dispatchChangePasswordRedux.servicePost(values);

    return true;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnMount
    >
      {({ errors, submitForm, isSubmitting }) => (
        <Box display="flex" flexDirection="column" gap={3}>
          <Box display="flex" flexDirection="column" gap={3}>
            <FieldPassword
              component={FieldLabelFormikComponent}
              name="password"
              label="Senha"
            />
            <FieldPassword
              component={FieldLabelFormikComponent}
              name="confirmPassword"
              label="Confirmação de senha"
            />
          </Box>
          <LoadingButton
            variant="contained"
            fullWidth
            size="large"
            disabled={Object.keys(errors).length > 0}
            onClick={submitForm}
            loading={selectorRedux.loading}
          >
            Enviar
          </LoadingButton>
        </Box>
      )}
    </Formik>
  );
};

const ChangePasswordScreen = () => {
  return (
    <BoxLoginLayout>
      <FormChangePassword />
    </BoxLoginLayout>
  );
};

export default ChangePasswordScreen;
