import reducer from './dialogConfirmExit.reducer';
import * as action from './dialogConfirmExit.action';
import * as selector from './dialogConfirmExit.selector';
import * as constant from './dialogConfirmExit.constant';
import initialState from './dialogConfirmExit.initialState';
import saga from './dialogConfirmExit.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  saga,
  name: constant.NAME,
};
