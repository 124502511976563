import React from 'react';
import { Box } from '@mui/material';

import LogoComponent from '@giro/shared-components/Logo.component';

const BoxLoginLayout = ({ children }) => {
  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        bgcolor="white"
        width={{ xs: '100%', sm: 610 }}
        borderRadius={{
          xs: '10px',
          sm: '50px',
        }}
        m={{
          xs: 1,
          sm: 0,
        }}
        p={{
          xs: 2,
          sm: 10,
        }}
      >
        <Box display="flex" flexDirection="column" gap={3}>
          <Box alignSelf="center">
            <img width="190" src="/logorc.png" />
          </Box>

          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default BoxLoginLayout;
