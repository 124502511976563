import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, CircularProgress } from '@mui/material';

import terminalStore from '../store';
import transactions from '@giro-pdv-terminal/store/transactions';
import pinpad from '@giro-pdv-terminal/store/pinpad';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import websocketUtil from '@giro-pdv-terminal/utils/websocket.util';

const ResultComponent = () => {
  const { user } = useAuthHook();

  const timeout: any = React.useRef(null);
  const dispatch = useDispatch();

  const terminalStoreSelector = useSelector(terminalStore.selector.selectState);
  const transactionSelector = useSelector(transactions.selector.selectState);

  React.useEffect(() => {
    if (terminalStoreSelector.resultStatus === null) {
      dispatch(terminalStore.action.updateResultStatus('loading'));

      dispatch(pinpad.action.updateSession(transactionSelector?.data?.meta));

      dispatch(pinpad.action.servicePostStart());
    }

    if (terminalStoreSelector.resultStatus !== null) {
      timeout.current = setTimeout(() => {
        dispatch(terminalStore.action.reset());
      }, 10 * 1000);
    }

    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  React.useEffect(() => {
    const lastLogSocket =
      terminalStoreSelector.logSocket[
        terminalStoreSelector.logSocket.length - 1
      ];

    const lastStates = lastLogSocket?.states[lastLogSocket?.states.length - 1];

    if (lastStates?.CdStatusTransacao === 2 || !lastStates?.CdStatusTransacao) {
      return undefined;
    }

    timeout.current = setTimeout(() => {
      dispatch(terminalStore.action.reset());
    }, 10 * 1000);

    if (lastStates?.CdStatusTransacao !== 3) {
      websocketUtil.addEvent(user?.linkedkey, {
        event: 'pdv-terminal::payment::error',
      });

      dispatch(terminalStore.action.updateResultStatus('error'));
    } else {
      websocketUtil.addEvent(user?.linkedkey, {
        event: 'pdv-terminal::payment::success',
      });

      dispatch(terminalStore.action.updateResultStatus('success'));
    }
  }, [terminalStoreSelector.logSocket]);

  const isLoading = terminalStoreSelector.resultStatus === 'loading';
  const isSuccess = terminalStoreSelector.resultStatus === 'success';
  const isError = terminalStoreSelector.resultStatus === 'error';

  const loadingHtml = (
    <Box
      height="calc(100vh - 260px)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      flexDirection="column"
      gap={3}
    >
      <CircularProgress sx={{ color: 'gray.500' }} size="60px" />
      <Typography variant="trasso_heading_medium" color="gray.500">
        Siga as instruções do pinpad
      </Typography>
      <img src="/instructions.png" width={300} />
    </Box>
  );

  const successHtml = (
    <Box
      height={'calc(100vh - 260px)'}
      bgcolor="success.main"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={2}
    >
      <Box alignSelf="center" color="white">
        <img
          src="/success.svg"
          width="100px"
          style={{ color: '#FFF', fill: '#FFF !important' }}
        />
      </Box>
      <Typography
        align="center"
        variant="header"
        color="white"
        lineHeight={1.1}
      >
        Pagamento efetuado <br />
        com sucesso!
      </Typography>
    </Box>
  );

  const errorHtml = (
    <Box
      height={'calc(100vh - 260px)'}
      bgcolor="error.light"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={2}
    >
      <Box alignSelf="center" color="white">
        <img
          src="/error.svg"
          width="100px"
          style={{ color: '#FFF', fill: '#FFF !important' }}
        />
      </Box>
      <Typography
        align="center"
        variant="header"
        color="white"
        lineHeight={1.1}
      >
        Aconteceu um erro <br />
        com seu pagamento
      </Typography>
    </Box>
  );

  return (
    <Box>
      {isLoading && loadingHtml}
      {isSuccess && successHtml}
      {isError && errorHtml}
    </Box>
  );
};

export default ResultComponent;
