import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ROUTES from '@giro-pdv-terminal/constants/routes.constant';

import auth from '@giro/shared-store/auth';

export default function useProtectedByAuth() {
  const history = useHistory();

  const selectorRedux = {
    signedIn: useSelector(auth.selector.selectSignedIn),
  };

  const { signedIn } = selectorRedux;

  const authclientUrl = ROUTES.LOGIN.ROOT;

  useEffect(() => {
    if (!signedIn) {
      history.replace(`${authclientUrl}?redirectUrl=${window.location.href}`);
    }
  }, [signedIn]);
}
