import { isEqual } from 'lodash';
import { takeLatest, put, select, delay, call } from 'redux-saga/effects';

import getApiReceiptsIdService from '@giro/shared-services/pdv/getApiReceiptsId.service';

import toaster from '@giro/shared-store/toaster';

import currentStore from '.';

function* handleNextStep() {
  const terminalSelector = yield select(currentStore.selector.selectState);

  const currentStep = terminalSelector?.step;

  const nextStep = currentStep + 1;

  yield put(
    currentStore.action.updateStep({
      next: nextStep,
      current: currentStep,
    })
  );
}

function* handlePrevStep() {
  const terminalSelector = yield select(currentStore.selector.selectState);

  const currentStep = terminalSelector?.step;

  const nextStep = currentStep - 1 < 0 ? 0 : currentStep - 1;

  yield put(
    currentStore.action.updateStep({
      next: nextStep,
      current: currentStep,
    })
  );
}

function* watch() {
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.UPDATE.STEP_NEXT,
    handleNextStep
  );
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.UPDATE.STEP_PREV,
    handlePrevStep
  );
}

export default {
  watch,
};
