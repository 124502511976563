import { flow } from 'lodash';

import { NAME } from './terminal.constant';

const selectStateKey = (state) => state[NAME];
const selectVisibleKey = (state) => state.visible;
const selectLoadingKey = (state) => state.loading;
const selectDataKey = (state) => state.data;
const selectErrorKey = (state) => state.error;
const selectPayloadKey = (state) => state.payload;
const selectCollapsesKey = (state) => state.collapses;

export const selectState = flow(selectStateKey);
export const selectLoading = flow(selectState, selectLoadingKey);
export const selectVisible = flow(selectState, selectVisibleKey);
export const selectPayload = flow(selectState, selectPayloadKey);
export const selectError = flow(selectState, selectErrorKey);
export const selectData = flow(selectState, selectDataKey);
export const selectCollapses = flow(selectState, selectCollapsesKey);
