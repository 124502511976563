import { createReducer } from '@reduxjs/toolkit';

import initialState from './terminal.initialState';
import { ACTION_TYPES } from './terminal.constant';

const handleReset = () => initialState;

const handleUpdateResultStatus = (state, action) => {
  return {
    ...state,
    resultStatus: action.payload,
  };
};

const handleUpdateState = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const handleUpdateAmount = (state, action) => {
  return {
    ...state,
    amount: action.payload,
  };
};

const handleUpdateStep = (state, action) => {
  return {
    ...state,
    step: action.payload?.next,
    lastStep: action.payload?.current,
  };
};

const handleUpdatePayment = (state, action) => {
  return {
    ...state,
    methodPayment: action.payload,
  };
};

const handleUpdateInstallment = (state, action) => {
  return {
    ...state,
    installmentSelected: action.payload,
  };
};

const handleUpdateLogSocket = (state, action) => {
  return {
    ...state,
    logSocket: [...state.logSocket, action.payload],
  };
};

export default createReducer(initialState, {
  [ACTION_TYPES.RESET]: handleReset,
  [ACTION_TYPES.UPDATE.STEP]: handleUpdateStep,
  [ACTION_TYPES.UPDATE.PAYMENT]: handleUpdatePayment,
  [ACTION_TYPES.UPDATE.INSTALLMENT]: handleUpdateInstallment,
  [ACTION_TYPES.UPDATE.RESULT_STATUS]: handleUpdateResultStatus,
  [ACTION_TYPES.UPDATE.LOG_SOCKET]: handleUpdateLogSocket,
  [ACTION_TYPES.UPDATE.AMOUNT]: handleUpdateAmount,
  [ACTION_TYPES.UPDATE.STATE]: handleUpdateState,
});
