export const NAME = 'transactions';

const CONTEXT = 'TRANSACTIONS';

const SERVICE_POST = `${CONTEXT}::SERVICE::POST`;
const SERVICE_PATCH = `${CONTEXT}::SERVICE::PATCH`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_SUCCESS = `${CONTEXT}::FETCH::SUCCESS`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;

const UPDATE_CURRENT = `${CONTEXT}::UPDATE::CURRENT`;

const UPDATE = {
  CURRENT: UPDATE_CURRENT,
};

const FETCH = {
  START: FETCH_START,
  SUCCESS: FETCH_SUCCESS,
  ERROR: FETCH_ERROR,
};

const SERVICE = {
  POST: SERVICE_POST,
  PATCH: SERVICE_PATCH,
};

const RESET = {
  STATE: RESET_STATE,
};

export const ACTION_TYPES = { SERVICE, FETCH, RESET, UPDATE };
