import React from 'react';
import { Box, GlobalStyles, Typography } from '@mui/material';

import Logo from '@giro/shared-components/Logo.component';

const WelcomeComponent = () => {
  return (
    <>
      <Box
        height="calc(100vh - 260px)"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={4}
      >
        <img width="300" src="/logorc.png" />
      </Box>
    </>
  );
};

export default WelcomeComponent;
