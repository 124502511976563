import { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useQuery from '@giro/shared-hooks/useQuery.hook';

import LoginLayout from '@giro-pdv-terminal/modules/login/layout/Login.layout';

import ROUTES from '@giro-pdv-terminal/constants/routes.constant';

import LoginScreen from './screens/Login.screen';
import ChangePasswordScreen from './screens/ChangePassword.screen';

import auth from '@giro/shared-store/auth';

import DialogForgotPassword from './components/DialogForgotPassword.component';

const LoginModule = () => {
  const query = useQuery();

  const history = useHistory();

  const selectorRedux = {
    signedIn: useSelector(auth.selector.selectSignedIn),
  };

  useEffect(() => {
    const redirectUrl = query.get('redirectUrl');
    const shouldHaveRedirectUrl = !!redirectUrl;

    if (shouldHaveRedirectUrl) {
      sessionStorage.setItem('redirectUrl', redirectUrl);
    }
  }, []);

  useEffect(() => {
    if (selectorRedux.signedIn) {
      const redirectUrlLocal = sessionStorage.getItem('redirectUrl');

      const shouldHaveRedirectUrlLocal = !!redirectUrlLocal;

      if (shouldHaveRedirectUrlLocal) {
        sessionStorage.removeItem('redirectUrl');

        (window as any).location.href = redirectUrlLocal;
      }

      if (!shouldHaveRedirectUrlLocal) {
        (window as any).location.href = process.env.REACT_APP_PDV_URL;
      }
    }
  }, [selectorRedux.signedIn]);

  return (
    <LoginLayout>
      <Switch>
        <Route exact path={ROUTES.LOGIN.ROOT} component={LoginScreen} />
        <Route
          exact
          path={ROUTES.LOGIN.CHANGE_PASSWORD}
          component={ChangePasswordScreen}
        />
      </Switch>

      <DialogForgotPassword />
    </LoginLayout>
  );
};

export default LoginModule;
