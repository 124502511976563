import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { flow, max } from 'lodash';

import SelectPaymentComponent from '../components/SelectPayment.component';
import SelectInstammentComponent from '../components/SelectInstamment.component';
import WelcomeComponent from '../components/Welcome.component';
import ResultComponent from '../components/Result.component';
import QrCodePixComponent from '../components/QrCodePix.component';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import websocketUtil from '@giro-pdv-terminal/utils/websocket.util';

import terminalStore from '../store';
import clients from '@giro-pdv-terminal/store/clients';
import transactions from '@giro-pdv-terminal/store/transactions';

import stepsObj from '../../../constants/steps.constant';

const StepComponent = {
  [stepsObj.welcome]: WelcomeComponent,
  [stepsObj['selectPayment']]: SelectPaymentComponent,
  [stepsObj['selectInstamment']]: SelectInstammentComponent,
  [stepsObj.showQrCode]: QrCodePixComponent,
  [stepsObj.result]: ResultComponent,
};

const TerminalScreen = () => {
  const [connectSocket, setConnectSocket] = React.useState(false);

  const dispatch = useDispatch();

  const { user } = useAuthHook();

  const dispatchRedux = {
    updateNext: flow(terminalStore.action.updateStepNext, dispatch),
    updatePrev: flow(terminalStore.action.updateStepPrev, dispatch),
    reset: flow(terminalStore.action.reset, dispatch),
  };

  const terminalStoreSelectorState = useSelector(
    terminalStore.selector.selectState
  );

  React.useEffect(() => {
    (async () => {
      await websocketUtil.connectWebSocket({
        onConnect: () => {
          setConnectSocket(true);
        },
      });
    })();

    return () => {
      websocketUtil.disconnectWebSocket();
    };
  }, []);

  React.useEffect(() => {
    if (!connectSocket) {
      return undefined;
    }

    const linkedKey = user?.linkedkey;

    websocketUtil.syncCode(linkedKey, (data) => {
      dispatch(terminalStore.action.updateLogSocket(data));
    });

    websocketUtil.addEvent(linkedKey, {
      event: 'pdv-terminal::request-client',
    });
  }, [connectSocket]);

  React.useEffect(() => {
    const lastLogSocket =
      terminalStoreSelectorState.logSocket[
        terminalStoreSelectorState.logSocket.length - 1
      ];

    const lastStates = lastLogSocket?.states[lastLogSocket?.states.length - 1];

    if (lastStates?.event === 'pdv::response-client') {
      dispatch(clients.action.fetchSuccess(lastStates?.payload));
    }

    if (lastStates?.event === 'pdv::init') {
      dispatchRedux.reset();

      dispatch(
        transactions.action.fetchSuccess(lastStates?.payload?.transaction)
      );

      dispatch(terminalStore.action.updateState(lastStates?.payload));

      dispatchRedux.updateNext();
    }

    if (lastStates?.event === 'pdv::close') {
      dispatchRedux.reset();
    }
  }, [terminalStoreSelectorState.logSocket]);

  const RenderComponent = StepComponent[terminalStoreSelectorState.step];

  const maxStep = max(Object.values(stepsObj));

  return (
    <Box>
      <RenderComponent />
    </Box>
  );
};

export default TerminalScreen;
