import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

export type dialogsPropsType = {
  handleClose: any;
  handleOpen: any;
  handleReset: any;
  visible: boolean;
  payload: any;
};

export default function useDialogHook(store): dialogsPropsType {
  const dispatch = useDispatch();

  const dispatchRedux = {
    OPEN: flow(store.action.open, dispatch),
    CLOSE: flow(store.action.close, dispatch),
    RESET: flow(store.action.reset, dispatch),
  };

  const selectorRedux = {
    visible: useSelector(store.selector.selectVisible),
    payload: useSelector(store.selector.selectPayload),
  };

  const props: dialogsPropsType = {
    handleClose: dispatchRedux.CLOSE,
    handleOpen: dispatchRedux.OPEN,
    handleReset: dispatchRedux.RESET,
    visible: (selectorRedux.visible as boolean) || false,
    payload: selectorRedux.payload,
  };

  return props;
}
