import React, { useEffect } from 'react';
import { flow } from 'lodash';
import { Switch, Route, Redirect } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import useAuth from '@giro/shared-hooks/useAuth.hook';
import useNotification from '@giro/shared-hooks/useNotification.hook';
import useIsCreaHook from '@giro-pdv-terminal/hooks/useIsCrea.hook';
import useIsMobile from './hooks/useIsMobile.hook';

import LoginModule from '@giro-pdv-terminal/modules/login/Login.module';
import TerminalModule from './modules/terminal/Terminal.module';

import ROUTES from './constants/routes.constant';

import DialogConfirmExitComponent from './components/DialogConfirmExit.component';

import menu from './store/menu';

const Routes = () => {
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const { user, signedIn } = useAuth();
  const dispatch = useDispatch();

  const dispatchRedux = {
    close: flow(menu.action.close, dispatch),
  };

  useNotification();

  useEffect(() => {
    if (isMobile) {
      dispatchRedux.close();
    }
  }, [pathname, isMobile]);

  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR === 'true') {
      hotjar.initialize(
        process.env.REACT_APP_HOTJAR_ID as any,
        process.env.REACT_APP_HOTJAR_SV as any
      );
    }
  }, []);

  useEffect(() => {
    if (signedIn && process.env.REACT_APP_HOTJAR === 'true') {
      const { user: userState } = user || {};

      if (hotjar.initialized()) {
        hotjar.identify(userState.id, {
          name: userState.name,
          email: userState.email,
        });
      }
    }
  }, [signedIn]);

  const ROOTROUTER = ROUTES.TERMINAL.ROOT;

  return (
    <>
      <Switch>
        <Route path={ROUTES.LOGIN.ROOT} component={LoginModule} />
        <Route path={ROUTES.TERMINAL.ROOT} component={TerminalModule} />

        <Redirect to={ROOTROUTER} />
      </Switch>

      <DialogConfirmExitComponent />
    </>
  );
};

export default Routes;
