import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import { useLocation, useHistory } from 'react-router-dom';
import { flow } from 'lodash';
import { useSelector } from 'react-redux';

import useIsCreaHook from '@giro-pdv-terminal/hooks/useIsCrea.hook';
import useAuthHook from '@giro/shared-hooks/useAuth.hook';
import useIsMobile from '@giro-pdv-terminal/hooks/useIsMobile.hook';
import useIsTotemHook from '@giro-pdv-terminal/hooks/useTotem.hook';

import Logo from '@giro/shared-components/Logo.component';

import clients from '@giro-pdv-terminal/store/clients';

const HeaderComponent = ({ handleToggleSide = () => {}, sideOpen }) => {
  const { user } = useAuthHook();

  const clientsStore = useSelector(clients.selector.selectState);

  return (
    <Box className="header-container" display="flex" flexDirection="column">
      <Box bgcolor="grey.100">
        <Container maxWidth="lg">
          <Box
            display="grid"
            gridTemplateColumns={'1fr 1fr 1fr'}
            alignItems="center"
            height="96px"
          >
            <Box display="flex" alignItems="center" gap={2} width="100%">
              <Tooltip
                arrow
                title={
                  <Box display="flex" flexDirection="column">
                    <Typography
                      align="center"
                      variant="trasso_button"
                      fontWeight={500}
                    >
                      Código de sincronização
                    </Typography>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography>
                        <strong>{user?.linkedkey}</strong>
                      </Typography>
                    </Box>
                  </Box>
                }
              >
                <img width="190" src="/logorc.png" />
              </Tooltip>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default HeaderComponent;
