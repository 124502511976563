import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import terminalStore from '../store';

import websocketUtil from '@giro-pdv-terminal/utils/websocket.util';

const BoxPayment = ({ label, onClick, img }) => {
  return (
    <Box
      bgcolor="primary.light"
      height={200}
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      flexDirection="column"
      gap={1}
      borderRadius={1}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Box component={'img'} src={img} alt="credit-card" />
      <Typography
        align="center"
        variant="trasso_heading_medium"
        color="grey.800"
        height={80}
        fontWeight={600}
      >
        {label}
      </Typography>
    </Box>
  );
};

const SelectPaymentComponent = () => {
  const { user } = useAuthHook();

  const dispatch = useDispatch();

  const terminalStoreSelectorState = useSelector(
    terminalStore.selector.selectState
  );

  const handleSetPayment = (payment) => () => {
    const linkedKey = user?.linkedkey;

    dispatch(terminalStore.action.updatePayment(payment));
    dispatch(terminalStore.action.updateStepNext());

    websocketUtil.addEvent(linkedKey, {
      event: 'pdv-terminal::method_payment',
      payload: {
        method_payment: payment,
      },
    });
  };

  return (
    <Box>
      <Box
        gap={4}
        display="grid"
        gridTemplateColumns="1fr 1fr"
        alignContent="center"
      >
        <Box gridColumn="2 span">
          <Typography align="center" variant="trasso_heading_medium">
            Valor a pagar:{' '}
            <strong>
              {Number(terminalStoreSelectorState.amount).toLocaleString(
                'pt-br',
                {
                  currency: 'BRL',
                  style: 'currency',
                }
              )}
            </strong>
          </Typography>
        </Box>

        <Box gridColumn="2 span">
          <Divider />
        </Box>

        <Box gridColumn="2 span" mb={-3}>
          <Typography
            align="center"
            variant="overline"
            fontWeight={800}
            color="gray.300"
          >
            Selecione o meio de pagamento
          </Typography>
        </Box>

        <BoxPayment
          img={'/cartao_giro.svg'}
          label={
            <span>
              Cartão de crédito <br />
              ou débito
            </span>
          }
          onClick={handleSetPayment('card')}
        />
        <BoxPayment
          img={'/pix_giro.svg'}
          label="PIX"
          onClick={handleSetPayment('pix')}
        />
      </Box>
    </Box>
  );
};

export default SelectPaymentComponent;
